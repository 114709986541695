
import {Component, Prop, Watch} from 'vue-property-decorator';
import ViewConfigurator from "@/components/ViewConfigurator.vue";
import {Action, Getter, State} from "vuex-class";
import {
    IAgencyDB, IBusinessSectorDB, IInterimDB, IUserDB, LabelValue, StoreAction
} from "@/types";
import InterimActions from "@/components/interim/InterimActions.vue";
import Visualisations from "@/components/Visualisations.vue";
import InterimMiniRead from "@/components/interim/InterimMiniRead.vue";
import moment from "moment-timezone";
import {ROUTES} from "@/data";
import {capitalize} from "@/helpers/commons";
import InterimForm from "@/components/interim/InterimForm.vue";
import clonedeep from "lodash.clonedeep";
import Dropzone from "@/components/Dropzone.vue";
import InlineField from "@/components/InlineField.vue";
import {showModal} from "@/helpers/callables";
import {mixins} from "vue-class-component";
import PageMixin from "@/mixins/PageMixin.vue";
import MegaTable from "@/components/MegaTable.vue";
import MissionTile from "@/components/tileContent/MissionTile.vue";
import InterimTile from "@/components/tileContent/InterimTile.vue";
import MapView from "@/components/MapView.vue";
import ActiveInterim from "@/components/modal/ActiveInterim.vue";
import InactiveInterim from "@/components/modal/InactiveInterim.vue";
import DeleteInterim from "@/components/modal/DeleteInterim.vue";
import Planning from "@/views/Planning.vue";
import InterimsPlanning from "@/components/InterimsPlanning.vue";
import AvailabilityZone from "@/components/interim/AvailabilityZone.vue";
import PaginationServer from "@/components/PaginationServer.vue";
import LockedAction from '@/components/modal/LockedAction.vue';
import {sortOptions} from '@/data';
import WorkerAddVerificationModal from '@/components/modal/WorkerAddVerificationModal.vue';
import ActionButton from '@/components/ActionButton.vue';
import NewIcon from '@/components/NewIcon.vue';

const URL_FIELDS = ["available", "incomplete", "askedChanges", "candidate", "employed"]

@Component({
    name: "Interims",
    components: {
        PaginationServer,
        AvailabilityZone,
        InterimsPlanning,
        Planning,
        MapView,
        InterimTile,
        DeleteInterim,
        InactiveInterim,
        ActiveInterim,
        MissionTile,
        MegaTable,
        InlineField,
        Dropzone,
        InterimForm,
        InterimMiniRead,
        Visualisations,
        InterimActions,
        ViewConfigurator,
        LockedAction,
        WorkerAddVerificationModal,
        ActionButton,
        NewIcon
    }
})
export default class Interims extends mixins(PageMixin) {
    @Prop() selectedView!: any;

    capitalize = capitalize;

    moment = moment;
    boxes: any = [];
    data: any = [];
    resizetimeout: any = 0;
    columnWidth = 0;
    $refs!: any;
    interims: any = [];
    loading = false;
    innerLoading = false;
    showMenu = [];
    showConfirmRemove = false;
    showConfirmRemoveGroup = false;
    showConfirmDisable = false;
    showConfirmDisableGroup = false;
    showActiveInterim = false;
    showConfirmRemoveDeactivatedInterimFromMission = false;
    showConfirmRemoveDeactivatedGroupFromMission= false;
    showConfirmRemoveDeletedInterimFromMission = false;
    showConfirmRemoveDeletedGroupFromMission= false;
    showWorkerAddVerificationModal = false;
    reason = "";
    reasonComplement = "";
    showInactiveInterim = false;
    showInactiveInterimGroup = false;
    showLockedAction = false;
    errors: any = {};
    globalErrors: any = [];
    invitationErrors: any = [];
    timeout: any = 0;
    selectedInterims: IInterimDB[] = [];
    interimToView: IInterimDB | null = null;
    interimToRemoveFromMission: IInterimDB | null = null;
    interimToRemove: IInterimDB | null = null;
    interimToActive: IInterimDB | null = null;
    interimToInactive: IInterimDB | null = null;
    groupInterimToInactive: IInterimDB[] | null = null;
    groupToRemove: IInterimDB[] | null = null;
    interimToDisable: IInterimDB | null = null;
    groupToDisable: IInterimDB[] | null = null;
    totalRecords = 0;
    filters: any = {};
    columns: any = [];
    file: any = null;
    uploadResults: any = null;
    query = "";
    viewFilters: any = {
        ...this.$route.meta!.viewFilters.reduce((acc: any, filter: any) => {
            acc[filter.name] = this.$route.query[filter.name] || filter.defaultValue;
            return acc;
        }, {}),
        ...Object.entries(this.$route.query).reduce((acc: any, [ filterKey, filterValue ]: any) => {
            if (URL_FIELDS.includes(filterKey)) acc[filterKey] = filterValue === 'true';
            return acc;
        }, {}),
    };
    mode = "list";
    missionTileMode = "gantt";
    jobs: any = [];
    selectedJob: any = null;
    modaleImportEvoliaId: any = {
        step: 0,
        evoliaImportIterimId: "",
        loading: false,
        receivedContent: {},
        workerId: null,
        error: "",
    }
    ROUTES = ROUTES;
    sortModifiers: {field: string, type: string}|undefined;

    nbIterimToInactive = 0;

    sortOption = sortOptions.descending;

    visualisations: any = {
        dateRange: {
            from: moment().startOf('week').valueOf(),
            to: moment().endOf('week').valueOf(),
        },
        view: 'interims',
        mode: 'list'
    };

    @Watch('visualisations.mode')
    listenOnChangeMode() {
        this.init();
    }

    apiUrl: string = <string>process.env.VUE_APP_API_URL?.replace('/1.0', '') + "/";

    pageTitle = "";

    @Action('actions/loadInterims') loadInterims!: StoreAction;
    @Action('actions/sendInterimInvitation') sendInterimInvitation!: StoreAction;
    @Action('actions/sendInterimInvitationOne') sendInterimInvitationOne!: StoreAction;
    @Action('actions/resendInterimInvitation') resendInterimInvitation!: StoreAction;
    @Action('actions/resendInterimInvitationOne') resendInterimInvitationOne!: StoreAction;
    @Action('actions/sendInterimDocument') sendInterimDocument!: StoreAction;
    @Action('actions/removeInterim') removeInterim!: StoreAction;
    @Action('actions/removeInterimGroup') removeInterimGroup!: StoreAction;
    @Action('actions/disableInterim') disableInterim!: StoreAction;
    @Action('actions/disableInterimGroup') disableInterimGroup!: StoreAction;
    @Action('actions/getMissions') getMissions!: StoreAction;
    @Action('actions/loadInterimJobs') loadInterimJobs!: StoreAction;
    @Action('actions/changeInterimActivity') changeInterimActivity!: StoreAction;
    @Action('actions/changeInterimActivityGroup') changeInterimActivityGroup!: StoreAction;
    @Action('actions/getInterimsAvailabilities') getInterimsAvailabilities!: StoreAction;
    @Action('actions/removeInterimFromMissions') removeInterimFromMissions!: StoreAction;
    @Action('actions/getEvoliaInterim') getEvoliaInterim!: StoreAction;
    @Action('actions/importEvoliaInterim') importEvoliaInterim!: StoreAction;
    @Action('actions/loadInterimsToBeInactive') loadInterimsToBeInactive!: StoreAction;

    @State('selectedCollaborators') selectedCollaborators!: IUserDB[];
    @State('selectedDomains') selectedDomains!: IBusinessSectorDB[];
    @State('currentAgency') currentAgency!: IAgencyDB;
    @State('superAdmin') superAdmin!: boolean;

    @Getter('isAgency') isAgency!: boolean;
    @Getter('isCompany') isCompany!: boolean;
    @Getter('userContext') userContext!: string;
    @Getter('isSupervisor') isSupervisor!: boolean;
    @Getter('isInteraction') isInteraction!: boolean;
    @Getter('isInteractionLimited') isInteractionLimited!: boolean
    @Getter('isAuthorizedAgency') isAuthorizedAgency!: boolean

    @Watch('selectedCollaborators', {deep: true}) onCollabs() {
        this.timedSearch();
    }

    @Watch('selectedDomains', {deep: true}) onDomains() {
        this.timedSearch();
    }

    @Watch('viewFilters', { deep: true }) onViewFilters(a:any, b:any) {
        const { available, incomplete, askedChanges, candidate, employed, ...filteredViewFilters } = this.viewFilters;
        const modifiers = {
            ...this.$route.query,
            ...this.viewFilters,
        }

        this.$router.push({ query: {
            ...modifiers
        }});

        this.timedSearch({
            ...modifiers,
            currentPage: 1,
            offset: 0,
            currentPerPage: 50,
        });
    }

    get isGanttMode() {
        return this.isGanttWorkers || this.isGanttAvailabilities;
    }

    get isGanttWorkers() {
        return this.storeSelectedView?.nameSlug === "gantt-workers";
    }

    get isGanttAvailabilities() {
        return this.$route.query.mode === "workers-availabilities";
    }

    get isGestionInactifs() {
        return this.$route.query.mode === "workers-to-be-inactive";
    }

    get maxHeight() {
        return this.$refs.main.$el.scrollHeight + "px";
    }

    get interimInactiveName() {
        return this.interimToInactive?.firstname + " " + this.interimToInactive?.lastname;
    }

    get interimRemoveName() {
        return this.interimToRemove?.firstname + " " + this.interimToRemove?.lastname;
    }

    get interimDisableName() {
        return this.interimToDisable?.firstname + " " + this.interimToDisable?.lastname;
    }

    get isInvitation() {
        return this.$route.name === ROUTES.APP.INTERIMS_INVITATIONS;
    }

    get isPool() {
        return this.$route.name === ROUTES.APP.INTERIMS_LIST;
    }

    get headers() {
        if (this.isCompany || this.isGanttAvailabilities) {
            const fromDate = moment(this.ranges.from);
            let headers: any = [];
            let i = 0, format;

            format = "dddd";
            while (i < 7) {
                const date = fromDate.clone().add(i, 'day');

                headers.push({
                    label: capitalize(date.format(format)),
                    sup: date.format('D'),
                    value: date.valueOf()
                });

                ++i;
            }

            headers.splice(0, 0, {
                label: <string>this.$t('page.clients.topbar.interims'),
                value: '_interims',
                filterable: false
            });

            return headers;
        }
        else {
            return [
                ...this.columns.map((column: any) => {
                    return {
                        label: this.$t('page.interims.table.' + column.id),
                        field: column.id,
                        sortable: column.sortable,
                        formatFn: this.formatCell(column.id),
                        filterOptions: {
                            enable: column.id !== "status" && column.filterable,
                        }
                    }
                }).filter((column: any) => {
                    return column.field !== "vehicle" && column.field !== "weekendShift" && column.field !== "nightShift"
                }),
                {
                    field: "__actions",
                    sortable: false,
                }
            ];
        }
    }

    get sortOptions() {
        return [
            sortOptions.descending,
            sortOptions.ascending,
            sortOptions.az,
            sortOptions.za
        ].map(o => { return { label: o, value: o } })
    }

    get rows() {
        return this.interims || []
    }

    get listMode() {
        return this.mode === 'list';
    }

    get addMode() {
        return this.mode === 'add';
    }

    get isListMode() {
        return !this.isGanttAvailabilities && this.visualisations.mode === "list";
    }

    get isGridMode() {
        return this.visualisations.mode === "grid";
    }

    get isJobs() {
        return this.visualisations.view === "jobs";
    }

    get isMapViewMode() {
        return this.visualisations.mode === "map";
    }

    get hasInterimsSelected() {
        return this.selectedInterims.length;
    }

    get pagination() {
        return {
            offset: this.filters.offset,
            limit: this.filters.limit,
            total: this.totalRecords,
        }
    }

    // set pagination(v:any) {
    //     this.timedSearch(v);
    // }
    //
    get ranges() {
        let fromDate, toDate;

        if (typeof this.visualisations.dateRange.from === 'number') {
            fromDate = this.visualisations.dateRange.from;
            toDate = this.visualisations.dateRange.to;
        }
        else {
            fromDate = this.visualisations.dateRange.from.valueOf();
            toDate = this.visualisations.dateRange.to.valueOf();
        }

        return {
            from: fromDate,
            to: toDate
        }
    }

    calculateMissions() {
        this.boxes = [];
        let data: any;
        if (this.isGanttAvailabilities) {
            data = clonedeep(this.interims);
            this.interims = []
        }
        else {
            data = clonedeep(this.data);
            this.data.forEach((i: any) => {
                i.missions = [];
            });
        }
        setTimeout(() => {
            //@ts-ignore;
            if (this.$refs?.table?.$refs?.columns?.$el) {
                //@ts-ignore;
                this.columnWidth = this.$refs.table.$refs.columns.$el.scrollWidth / 8; // 8 columns
            }

            setTimeout(() => {
                if (this.isGanttAvailabilities) {
                    this.interims = clonedeep(data);
                }
                else {
                    this.data = data.map((item: any) => {
                        let sortedData: any = [];
                        item.missions?.forEach((mission: any) => {
                            let finalFrom, finalTo;

                            if (mission.from > this.ranges.to || mission.to < this.ranges.from) {
                                return;
                            }
                            if (mission.from < this.ranges.from) {
                                mission._before = true;
                                finalFrom = this.ranges.from;
                            }
                            else {
                                finalFrom = mission.from;
                            }
                            if (mission.to > this.ranges.to) {
                                mission._after = true;
                                finalTo = this.ranges.to;
                            }
                            else {
                                finalTo = mission.to;
                            }

                            let diffDay: number = (moment(finalTo).diff(moment(finalFrom), 'day') + 1);

                            mission._left = (moment(finalFrom).weekday() * this.columnWidth);
                            mission._width = (diffDay * this.columnWidth) - 4;

                            if (mission._left + mission._width > this.columnWidth * 7) {
                                mission._width = this.columnWidth * 7 - mission._left - 4;
                            }

                            mission._left += 'px';
                            mission._width += 'px';

                            mission.finalFrom = finalFrom;
                            mission.finalTo = finalTo;

                            if (!sortedData.length) {
                                sortedData.push([mission]);
                            }
                            else {
                                let found = false;

                                for (let i = 0, len = sortedData.length; i < len; ++i) {
                                    for (let j = 0, len2 = sortedData[i].length; j < len2; ++j) {
                                        const m = sortedData[i][j];
                                        const mFrom = moment(m.finalFrom).startOf('day');
                                        const mTo = moment(m.finalTo).endOf('day');
                                        const iFrom = moment(finalFrom).startOf('day');
                                        const iTo = moment(finalTo).endOf('day');
                                        const range = moment.range(mFrom, mTo);

                                        found = !range.contains(iFrom) && !range.contains(iTo);
                                    }
                                    if (found) {
                                        sortedData[i].push(mission);
                                        break;
                                    }
                                }
                                if (!found) {
                                    sortedData.push([mission]);
                                }
                            }
                        });
                        item.sortedMissions = sortedData;

                        return item;
                    });
                }
                this.$forceUpdate();
                this.$nextTick(() => {
                    if (this.$refs?.table?.$refs?.columns?.$el) {
                        (this.$refs.table.$refs.columns.$el.querySelectorAll('.column') || []).forEach((el: any) => el.style.height = '100%');
                        this.$nextTick(() => {
                            if (this.$refs?.table?.$refs?.columns?.$el) {
                                let height = this.$refs.table.$refs.columns.$el.scrollHeight + 'px';

                                (this.$refs.table.$refs.columns.$el.querySelectorAll('.column') || []).forEach((el: any) => el.style.height = height);
                            }
                        })
                    }
                })
            }, 2);
        });
        this.$forceUpdate();
    }

    onResize() {
        if (this.resizetimeout) {
            clearTimeout(this.resizetimeout);
        }

        this.resizetimeout = setTimeout(() => {
            this.calculateMissions();
        }, 200);
    }

    NaNOrValue(option: LabelValue) {
        return option && (typeof option.value === "number" && isNaN(option.value) ? option.label : option.value)
    }

    toggleMode() {
        if (this.addMode) {
            this.mode = 'list';
            this.errors = {};
        }
        else {
            this.mode = "add";
        }
    }

    async onDisplayChange(displayMode: string) {

        this.visualisations.view = displayMode;
        const modifiers: any = {
            view: displayMode
        };
        if (displayMode === 'jobs') {
            console.log("slug", this.storeSelectedView?.nameSlug);
            this.jobs = await this.loadInterimJobs({view: 'workers'})
            modifiers.jobId = this.jobs[0]._id
            this.selectedJob = this.jobs[0];
        }
        this.$router.push({ query: {
            ...modifiers
        }});
        this.timedSearch({
            ...modifiers,
            currentPage: 1,
            offset: 0,
            currentPerPage: 50
        });
        this.interims = [];
    }

    onPaginationChange(pageModifiers: any) {
        this.$router.push({
            query: {
                ...this.$route.query,
                page: pageModifiers?.currentPage || 1,
                offset: pageModifiers?.offset || 0,
                perPage: pageModifiers?.currentPerPage || 50
            }
        })

        this.timedSearch({
            ...this.$route.query,
            ...pageModifiers
        });
    }

    async onDateChange(dateRange: any) {
        this.visualisations.dateRange = dateRange;

        this.filters.mode = "planningWorkers";
        const result = await this.getInterimsAvailabilities({
            offset: this.filters.offset,
            limit: this.filters.limit,
            search: this.filters.search,
            jobs: this.filters.viewFilters.jobs, ...this.ranges
        });
        this.interims = result.workers;
        this.totalRecords = result.pagination.total;
        this.calculateMissions();

        const modifiers = {
            ...this.$route.query,
            from: dateRange.from,
            to: dateRange.to,
        }

        this.$router.push({
            query: modifiers
        })
    }

    timedSearch(modifiers?: any) {
        this.loading = true;
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.init(modifiers);
        }, 300);
    }

    lineClicked(row: any) {
        const target: any = row.event?.currentTarget;
        if (target?.classList.contains('vgt-checkbox-col') || target?.tagName === 'input') {
            // checkbox clicked
        }
        else {
            this.$router.push({
                name: ROUTES.APP.INTERIM,
                params: {interimId: row.row._id}
            })
        }
    }

    isDateCell(key: string) {
        return [
            "lastMission",
            "invitationDate",
            "createdAt"
        ].indexOf(key) !== -1;
    }

    formatCell(key: string) {
        return (value: any) => {
            if (key === "firstname" || key === "lastname") {
                return capitalize(value);
            }
            if (key === "invitationDate") {
                if (!value) {
                    return this.$t('global.new');
                }
            }
            if (key === "status") {
                return (value && this.$t('mission.workerStatus.' + value + '.' + this.userContext)) || '';
            }
            if (Array.isArray(value)) {
                if (key === "jobNames") {
                    return value.map(capitalize).join(', ');
                }
                return value.join(', ');
            }
            if (typeof value === "boolean") {
                return value ? this.$t('global.yes') : this.$t('global.no');
            }
            if (typeof value === "number" && this.isDateCell(key)) {
                return moment(value).format(<string>this.$t('date.format'));
            }
            return value;
        }
    }

    afterFormat(row: any, value: any) {
        if (row.column.field === "status") {
            if (row.row.inactiveByAgencies && row.row.inactiveByAgencies[this.currentAgency._id] && (row.row.agenciesList?.includes(this.currentAgency._id))) {
                return this.$t('mission.workerStatus.inactive.' + this.userContext);
            }
            if (row.row.inactiveByAgencies && row.row.inactiveByAgencies[this.currentAgency._id] && !(row.row.agenciesList?.includes(this.currentAgency._id))) {
                return this.$t('mission.workerStatus.no_longer_registered.' + this.userContext);
            }
        }
        return value;
    }

    onSelectedRowsChange(data: any) {
        this.selectedInterims = data.selectedRows;
    }

    interimAction_invite(interims: IInterimDB[]) {
        return (_event: any) => {
            interims.forEach(this.sendInvitationOne);
        }
    }

    interimAction_resend(interims: IInterimDB[]) {
        return (_event: any) => {
            interims.forEach(this.resendInvitationOne);
        }
    }

    interimAction_inviteGroup(interims: IInterimDB[]) {
        return (_event: any) => {
            this.sendInvitationGroup(interims);
        }
    }

    interimAction_resendGroup(interims: IInterimDB[]) {
        return (_event: any) => {
            this.resendInvitationGroup(interims);
        }
    }

    interimAction_viewDetail(interim: IInterimDB) {
        return (event: any) => {
            // this.interimToView = interim;
            event.stopImmediatePropagation();
            this.$router.push({
                name: ROUTES.APP.INTERIM,
                params: {interimId: interim._id}
            })
        }
    }

    goToInterim(row: any) {
        const target: any = row.event?.target;
        if (target) {
            if (target.classList.contains("vgt-checkbox-col") || (target.tagName.toLowerCase() === "input" && target.type === "checkbox")) {
                return;
            }
        }
        this.$router.push({
            name: ROUTES.APP.INTERIM,
            params: {interimId: row.row._id}
        })
    }

    interimAction_disable(interims: IInterimDB[]) {
        return (_event: any) => {
            if (interims.length === 1) {
                this.interimToDisable = interims[0];
                showModal.call(this, 'ConfirmDisable')
            }
            else {
                this.groupToDisable = interims;
                showModal.call(this, 'ConfirmDisableGroup')
            }
        }
    }

    interimAction_remove(interims: IInterimDB[]) {
        return (_event: any) => {
            if (interims.length === 1) {
                this.interimToRemove = interims[0];
                this.showConfirmRemove = true;
                // showModal.call(this, 'ConfirmRemove')
            }
            else {
                this.groupToRemove = interims;
                this.showConfirmRemoveGroup = true;
            }
            showModal.call(this, 'DeleteInterim');
        }
    }

    interimAction_active(interims: IInterimDB) {
        return (_event: any) => {
            this.interimToActive = interims;
            // showModal.call(this, 'ActiveInterim')
            this.submitActive({});
        }
    }

    interimAction_inactive(interims: IInterimDB[]) {
        return (_event: any) => {
            this.interimToInactive = null;
            this.groupInterimToInactive = null
            if (interims.length === 1) {
                this.interimToInactive = interims[0];
                this.nbIterimToInactive = 1;
                this.showInactiveInterim = true;
            }
            else {
                this.nbIterimToInactive = interims.length;
                this.groupInterimToInactive = interims;
                this.showInactiveInterimGroup = true;
            }
            showModal.call(this, 'InactiveInterim');
        }
    }

    interimActions(index: number) {
        const interim: IInterimDB = this.interims[index];

        return {
            active: this.interimAction_active(interim),
            inactive: this.interimAction_inactive([interim]),
            viewDetails: this.interimAction_viewDetail(interim),
            disable: this.interimAction_disable([interim]),
            remove: this.interimAction_remove([interim]),
            invite: this.interimAction_invite([interim]),
            resend: this.interimAction_resend([interim]),
        }
    }

    groupedActions() {
        return {
            disable: this.interimAction_disable(this.selectedInterims),
            inactive: this.interimAction_inactive(this.selectedInterims),
            invite: this.interimAction_inviteGroup(this.selectedInterims),
            remove: this.interimAction_remove(this.selectedInterims),
            resend: this.interimAction_resendGroup(this.selectedInterims),
        }
    }

    onModifier(modifiers: any) {
        if (this.selectedJob !== null) {
            modifiers.view = "jobs";
            modifiers.jobId = this.selectedJob._id;
        }
        this.sortModifiers = modifiers.sort;
        if(modifiers.sort.type === "none") this.sortModifiers = undefined;

        this.timedSearch(modifiers);
    }

    get getGroupStatus() {
        return this.formattedViewFilters.status;
    }

    get formattedViewFilters() {
        if (this.isCompany) {
            return {
                search: this.viewFilters.search || undefined,
                jobs: (this.viewFilters.jobs?.length && this.viewFilters.jobs.map((j: LabelValue) => j.value)) || undefined,
            }
        }

        return {
            zip: this.viewFilters.zip || undefined,
            jobs: (this.viewFilters.jobs?.length && this.viewFilters.jobs.map((j: LabelValue) => j.value)) || undefined,
            status: (this.viewFilters.status?.length && this.viewFilters.status.map((j: LabelValue) => j.value)) || undefined,
            hasVehicle: this.viewFilters.hasVehicle === 'yes' ? true : this.viewFilters.hasVehicle === 'no' ? false : undefined,
            hasNightAvailable: this.viewFilters.hasNightAvailable === 'yes' ? true : this.viewFilters.hasNightAvailable === 'no' ? false : undefined,
            hasWeekendAvailable: this.viewFilters.hasWeekendAvailable === 'yes' ? true : this.viewFilters.hasWeekendAvailable === 'no' ? false : undefined,
            askedChanges: this.viewFilters.askedChanges,
            incomplete: this.viewFilters.incomplete,
            available: this.viewFilters.available,
            candidate: this.viewFilters.candidate,
            employed: this.viewFilters.employed,
        }
    }

    registerView(modelName: string) {
        this.$emit('registerView', {
            modelName,
            filters: this.formattedViewFilters,
            view: this.visualisations.view,
            mode: this.visualisations.mode
        });
    }

    eraseView(selectedView: any) {
        this.$emit('eraseView', {
            nameSlug: selectedView.nameSlug,
            selectedView,
            filters: this.formattedViewFilters,
            view: this.visualisations.view,
            mode: this.visualisations.mode
        });
    }

    downloadModel() {
        this.$refs.link.click();
    }

    async selectJob(job: any) {
        const modifiers = {
            ...this.$route.query,
            jobId: job._id,
            view: 'jobs',
            page: 1,
            offset: 0,
            perPage: 50
        };

        this.$router.push({
            // Erreur compilo wtf
            //@ts-ignore
            query: modifiers
        })

        if (job._id !== this.selectedJob?._id) {
            this.interims = [];
            this.selectedJob = job;
            this.filters.currentPage = 1;
        }

        this.timedSearch({
            currentPage: this.filters?.currentPage,
            currentPerPage: 50,
            view: "jobs",
            jobId: job._id
        });
    }

    onCustomPagination(value: any) {
        console.log(value);
    }

    //controle l'etat complet ou non d'un profil interimaire
    fieldsCheck(interim: any): object {

        // Initialize the status of the field
        const fieldStatusDetails: {[key: string]: any} = {'errorMessage': this.$t("errors.Interims.emptyField").toString()};
        for ( const key of Object.keys(interim) ) {
            fieldStatusDetails[key] = true;
        }

        if ((interim.firstname === undefined) || (interim.firstname === null) || (interim.firstname.trim().length === 0)) {
            fieldStatusDetails.firstname = false;
        }
        if ((interim.lastname === undefined) || (interim.lastname === null) || (interim.lastname.trim().length === 0)) {
            fieldStatusDetails.lastname = false;
        }
        if ((interim.phone === undefined) || (interim.phone === null) || (interim.phone.trim().length === 0)) {
            fieldStatusDetails.phone = false;
        }
        if ((interim.email === undefined) || (interim.email === null) || (interim.email.trim().length === 0)) {
            fieldStatusDetails.email = false;
        }
        if (interim.jobNames[0] === null) {
            fieldStatusDetails.jobNames = false;
        }
        if (interim.city === null || (interim.city === undefined) || (interim.city.trim().length === 0)) {
            fieldStatusDetails.city = false;
        }
        return fieldStatusDetails;
    }

    getRowFieldsStatus(interim: any): any {
        return interim.rowFieldsStatus;
    }

    displaylock() {
        showModal.call(this, 'LockedAction');
    }


    async submitActive(data: any) {
        this.loading = true;
        try {
            if (this.interimToActive) {
                await this.changeInterimActivity({
                    interimId: this.interimToActive._id,
                    status: "active",
                    reason: data.reason
                });
                await this.init({currentPage: this.filters.currentPage, currentPerPage: this.filters.currentPerPage });
                this.interimToActive = null;
            }
        } catch (e) {
            console.log(e);
        }
        this.loading = false;
    }

    async submitInactive(data: any) {
        this.loading = true;
        this.reason = data.reason;
        this.reasonComplement = data.reasonComplement;
        try {
            if (this.interimToInactive) {
                await this.changeInterimActivity({
                    interimId: this.interimToInactive._id,
                    status: "inactive",
                    reason: data.reason,
                    reasonComplement: data.reasonComplement
                });
                this.showConfirmRemoveDeactivatedInterimFromMission = true;
                showModal.call(this, 'ConfirmRemoveDeactivatedInterimFromMission');
            }
            if (this.groupInterimToInactive?.length) {
                const interimIds: string[] = this.groupInterimToInactive.map((a: any) => a._id);
                await this.changeInterimActivityGroup({
                    interimIds: interimIds,
                    status: "inactive",
                    inactivityReason: data.reason,
                    reasonComplement: data.reasonComplement
                });
                this.showConfirmRemoveDeactivatedGroupFromMission = true;
                showModal.call(this, 'ConfirmRemoveDeactivatedGroupFromMission');
            }
            this.nbIterimToInactive = 0;
            this.$modal.hide('InactiveInterim');
            await this.init({currentPage: this.filters.currentPage, currentPerPage: this.filters.currentPerPage });
        } catch (e) {
            console.log(e);
        }
        this.loading = false;
    }

    async submitDelete(data: any) {
        this.loading = true;
        this.reason = data.reason;
        this.reasonComplement = data.reasonComplement;
        try {
            if (this.interimToRemove) {
                await this.removeInterim({
                    interimId: this.interimToRemove._id,
                    reason: data.reason,
                    reasonComplement: data.reasonComplement
                });
                this.showConfirmRemoveDeletedInterimFromMission = true;
                showModal.call(this, 'ConfirmRemoveDeletedInterimFromMission');
            }
            if (this.groupToRemove?.length) {
                await this.removeInterimGroup({
                    interimIds: this.groupToRemove.map((a: any) => a._id),
                    reason: data.reason,
                    reasonComplement: data.reasonComplement
                });
                this.showConfirmRemoveDeletedGroupFromMission = true;
                showModal.call(this, 'ConfirmRemoveDeletedGroupFromMission');
            }
            this.$modal.hide('DeleteInterim');
            // this.$modal.hide('ConfirmRemoveGroup');
            await this.init({currentPage: this.filters.currentPage, currentPerPage: this.filters.currentPerPage });
        } catch (e) {
            console.log(e);
        }
        this.loading = false;
    }

    async submitDisable() {
        this.loading = true;
        try {
            if (this.interimToDisable) {
                await this.disableInterim(this.interimToDisable._id);
                this.interimToDisable = null;
            }
            if (this.groupToDisable?.length) {
                await this.disableInterimGroup(this.groupToDisable.map((a: any) => a._id));
                this.groupToDisable = null;
            }
            await this.init({currentPage: this.filters.currentPage, currentPerPage: this.filters.currentPerPage });
        } catch (e) {
            console.log(e);
        }
        this.loading = false;
    }

    async submitRemoveFromMissions() {
        this.loading = true;
        try {
            if (this.interimToInactive) {
                console.log("Interim " + this.interimInactiveName + " to remove from active missions.");
                const RESULT = await this.removeInterimFromMissions({
                    interimId: this.interimToInactive._id,
                    reason: this.reason?this.reason:'',
                    reasonComplement: this.reasonComplement?this.reasonComplement:''
                });
                if (RESULT.success) {
                    console.log(`Interim " + ${this.interimInactiveName} has been remove from ${RESULT.nbUpdatedMissions} missions.`);
                }
                this.showConfirmRemoveDeactivatedInterimFromMission = false;
                this.interimToInactive = null;
                this.$modal.hide('ConfirmRemoveDeactivatedInterimFromMission');
            }
            if (this.groupInterimToInactive?.length)  {
                // const interimNames: string[] = this.groupInterimToInactive.map((a: any) => a.firstname);
                for (const interimToInactive of this.groupInterimToInactive) {
                    const RESULT = await this.removeInterimFromMissions({
                        interimId: interimToInactive._id,
                        reason: this.reason?this.reason:'',
                        reasonComplement: this.reasonComplement?this.reasonComplement:''
                    });
                    if (RESULT.success) {
                        console.log(`Interim " + ${this.interimInactiveName} has been remove from ${RESULT.nbUpdatedMissions} missions.`);
                    }
                }
                this.showConfirmRemoveDeactivatedGroupFromMission = false;
                this.groupInterimToInactive = null;
                this.$modal.hide('ConfirmRemoveDeactivatedGroupFromMission');
            }
            if (this.interimToRemove) {
                const RESULT = await this.removeInterimFromMissions({
                    interimId: this.interimToRemove._id,
                    reason: this.reason?this.reason:'',
                    reasonComplement: this.reasonComplement?this.reasonComplement:''
                });
                if (RESULT.success) {
                    console.log(`Interim " + ${this.interimInactiveName} has been remove from ${RESULT.nbUpdatedMissions} missions.`);
                }
                this.showConfirmRemoveDeactivatedInterimFromMission = false;
                this.interimToRemove = null;
                this.$modal.hide('ConfirmRemoveDeletedInterimFromMission');
            }
            if (this.groupToRemove?.length)  {
                // const interimNames: string[] = this.groupToRemove.map((a: any) => a.firstname);
                for (const interimToRemove of this.groupToRemove) {
                    const RESULT = await this.removeInterimFromMissions({
                        interimId: interimToRemove._id,
                        reason: this.reason?this.reason:'',
                        reasonComplement: this.reasonComplement?this.reasonComplement:''
                    });
                    if (RESULT.success) {
                        console.log(`Interim " + ${this.interimInactiveName} has been remove from ${RESULT.nbUpdatedMissions} missions.`);
                    }
                }
                this.showConfirmRemoveDeactivatedGroupFromMission = false;
                this.groupToRemove = null;
                this.reason = '';
                this.reasonComplement = '';
                this.$modal.hide('ConfirmRemoveDeletedGroupFromMission');
            }
            this.loading = false;
        } catch(e) {
            console.log(e);
            this.showConfirmRemoveDeactivatedInterimFromMission = false;
            this.interimToInactive = null;
            this.$modal.hide('ConfirmRemoveDeactivatedInterimFromMission');
            this.showConfirmRemoveDeactivatedGroupFromMission = false;
            this.groupInterimToInactive = null;
            this.$modal.hide('ConfirmRemoveDeactivatedGroupFromMission');
            this.showConfirmRemoveDeactivatedInterimFromMission = false;
            this.interimToRemove = null;
            this.$modal.hide('ConfirmRemoveDeletedInterimFromMission');
            this.showConfirmRemoveDeactivatedGroupFromMission = false;
            this.groupToRemove = null;
            this.$modal.hide('ConfirmRemoveDeletedGroupFromMission');
            this.reason = '';
            this.reasonComplement = '';
            this.loading = false;
        }
    }

    async upload() {
        this.loading = true;
        this.invitationErrors = [];
        this.uploadResults = null;
        try {
            this.uploadResults = await this.sendInterimDocument({
                data: this.file
            });
            this.file = "";
            await this.init({currentPage: this.filters.currentPage, currentPerPage: this.filters.currentPerPage });
            this.$forceUpdate();
        } catch (e) {
            console.log(e);
            this.invitationErrors = [{id: "Invalid.Document"}];
        }
        this.loading = false;
    }

    async sendInvitationOne(interim: IInterimDB) {
        this.innerLoading = true;
        try {
            await this.sendInterimInvitationOne({
                interimId: interim._id
            });
            await this.init({currentPage: this.filters.currentPage, currentPerPage: this.filters.currentPerPage });
        } catch (e) {
            console.log(e);
        }
        this.innerLoading = false;
    }

    async sendInvitationGroup(_interims: IInterimDB[]) {
        this.innerLoading = true;
        try {
            await this.sendInterimInvitation();
            await this.init({currentPage: this.filters.currentPage, currentPerPage: this.filters.currentPerPage });
        } catch (e) {
            console.log(e);
        }
        this.innerLoading = false;
    }

    async resendInvitationGroup(_interims: IInterimDB[]) {
        this.innerLoading = true;
        try {
            await this.resendInterimInvitation();
            await this.init({currentPage: this.filters.currentPage, currentPerPage: this.filters.currentPerPage });
        } catch (e) {
            console.log(e);
        }
        this.innerLoading = false;
    }

    async resendInvitationOne(interim: IInterimDB) {
        this.innerLoading = true;
        try {
            await this.resendInterimInvitationOne({interimId: interim._id});
            await this.init({currentPage: this.filters.currentPage, currentPerPage: this.filters.currentPerPage });
        } catch (e) {
            console.log(e);
        }
        this.innerLoading = false;
    }

    openEvoliaAddInterimModal() {
        showModal.call(this, 'ConfirmEvoliaAddIterimForm')
    }

    closeEvoliaAddInterimModal() {
        this.modaleImportEvoliaId.step = 0;
        this.modaleImportEvoliaId.evoliaImportIterimId = '';
        this.modaleImportEvoliaId.error = '';
        this.modaleImportEvoliaId.receivedContent = {};
    }

    async submitEvoliaInterim() {
        this.modaleImportEvoliaId.loading = true;

        const data = {
            itemId: this.modaleImportEvoliaId.evoliaImportIterimId,
            agencyId: this.currentAgency._id,
            agencyCode: this.currentAgency.contractManagerIdentifier,
            itemType: "import",
            workerId: this.modaleImportEvoliaId.receivedContent?.workerId
        };

        // step 0, write evolia id
        if(this.modaleImportEvoliaId.step === 0) {
            try {
                const candidate = await this.getEvoliaInterim(data);

                this.modaleImportEvoliaId.step++;
                this.modaleImportEvoliaId.receivedContent = candidate;

                this.modaleImportEvoliaId.loading = false;

                return;
            }
            catch (e) {
                console.log(e);
                this.modaleImportEvoliaId.error = e.errors;
                this.modaleImportEvoliaId.loading = false;
            }
        }

        // step 1, display evolia content and validate insert
        if(this.modaleImportEvoliaId.step === 1) {
            try {
                const imported = await this.importEvoliaInterim(data);
                console.log(imported);

                this.$modal.hide('ConfirmEvoliaAddIterimForm');

                this.$toast.open({
                    message: `${this.modaleImportEvoliaId.receivedContent.firstname} ${this.modaleImportEvoliaId.receivedContent.lastname} importé avec succés`,
                    type: "success",
                    duration: 10000,
                });

                this.modaleImportEvoliaId.loading = false;
                return;
            }
            catch (e) {
                console.log(e);
                this.modaleImportEvoliaId.loading = false;
            }
        }
    }

    async init(modifiers?: any) {
        if (this.$route.query.view) {
            this.visualisations.view = this.$route.query.view;
        }
        this.loading = true;
        try {
            if (this.isCompany) {
                const data: any = {...this.ranges, ...this.formattedViewFilters};

                data.subview = "gantt-workers";
                this.data = await this.getMissions(data);
                this.calculateMissions();
            }
            else {
                if (modifiers) {
                    this.filters = {
                        currentPage: modifiers.currentPage,
                        currentPerPage: modifiers.currentPerPage,
                        offset: (modifiers.currentPage - 1) * modifiers.currentPerPage,
                        limit: modifiers.currentPerPage,
                        sort: modifiers.sort || this.sortModifiers,
                        columnFilters: modifiers.columnFilters && ({
                            ...modifiers.columnFilters,
                            businessSectorId: modifiers.columnFilters.businessSector
                        }) || undefined,
                    };

                    if (this.filters.columnFilters?.businessSector) {
                        delete this.filters.columnFilters.businessSector;
                    }
                }
                else {
                    this.filters = {
                        currentPage: 1,
                        currentPerPage: 50,
                        offset: 0,
                        limit: 50,
                        sort: this.sortModifiers,
                    };
                    this.interims = [];
                }

                this.filters.search = this.viewFilters.search || undefined;
                this.filters.incomplete = this.formattedViewFilters.incomplete;
                this.filters.available = this.formattedViewFilters.available;
                this.filters.askedChanges = this.formattedViewFilters.askedChanges;
                this.filters.candidate = this.formattedViewFilters.candidate;
                this.filters.employed = this.formattedViewFilters.employed;
                this.filters.viewFilters = this.formattedViewFilters;

                this.filters.view = this.visualisations.view;
                this.filters.mode = this.visualisations.mode;

                if (modifiers?.view === "jobs" && modifiers?.jobId) {
                    this.filters.view = "list";
                    this.filters.subview = "jobs";
                    this.filters.viewFilters.jobs = [modifiers.jobId];
                }
                if (this.filters.mode === "grid") {
                    this.filters.mode = "list";
                }
                let result: any;

                if (this.isGestionInactifs) {
                    this.filters.nbMission = this.viewFilters.nbMission || 20;
                    this.filters.tauxNonReponse = this.viewFilters.tauxNonReponse || this.$route.query?.tauxNonReponse || 100;

                    result = await this.loadInterimsToBeInactive(this.filters);
                    this.columns = result.columns;
                    for (let i = 0; i < result.rows.length; i++) {
                            result.rows[i].rowFieldsStatus = this.fieldsCheck(result.rows[i]);
                        }
                    this.interims = result.rows;
                } else if (this.isGanttAvailabilities) {
                    this.filters.mode = "planningWorkers";
                    result = await this.getInterimsAvailabilities({
                        offset: this.filters.offset,
                        limit: this.filters.limit,
                        search: this.filters.search,
                        jobs: this.filters.viewFilters.jobs, ...this.ranges
                    });
                    this.interims = result.workers;
                    this.totalRecords = result.pagination.total;
                    this.calculateMissions();
                } else {
                    if (this.filters.mode === 'map') {
                        result = await this.loadInterims({
                            viewFilters: this.filters.viewFilters,
                            view: this.filters.view,
                            mode: this.filters.mode
                        });
                        this.interims = result.rows;
                    } else {
                        result = await this.loadInterims(this.filters);
                        this.columns = result.columns;
                        // ajouter l'état complet/incomplet à chaque interim
                        for (let i = 0; i < result.rows.length; i++) {
                            result.rows[i].rowFieldsStatus = this.fieldsCheck(result.rows[i]);
                        }
                        this.interims = result.rows;
                    }
                }

                if (this.filters.mode !== "map") {
                    this.totalRecords = result.pagination.total;
                }
                else {
                    this.totalRecords = this.interims.length;
                }

                if (modifiers?.view === "jobs" && modifiers?.jobId) {
                    this.filters.viewFilters.jobs = [];
                }

            }
        } catch (e) {
            this.errors = e.errors;
            this.globalErrors = e._global;
            console.log(e);
        }
        this.loading = false;
    }

    async fetchInterims() {
        const result = await this.loadInterims(this.filters);
        this.columns = result.columns;
        // ajouter l'état complet/incomplet à chaque interim
        for (let i = 0; i < result.rows.length; i++) {
            result.rows[i].rowFieldsStatus = this.fieldsCheck(result.rows[i]);
        }
        this.interims = result.rows;
    }

    hasChanges(row: any) {
        return row.askedChanges && Object.entries(row.askedChanges)
                                         .filter(([key, value]) => key !== 'gender')
                                         .some(([key, value]) => value);
    }

    sortChanged(sort: string) {
        switch(sort) {
            case sortOptions.az:
                this.sortAZ(this.jobs);
                this.selectJob(this.jobs[0]);
                break;
            case sortOptions.za:
                this.sortZA(this.jobs);
                this.selectJob(this.jobs[0]);
                break;
            case sortOptions.ascending:
                this.sortAscending(this.jobs);
                this.selectJob(this.jobs[0]);
                break;
            case sortOptions.descending:
                this.sortDescending(this.jobs);
                this.selectJob(this.jobs[0]);
                break;
        };
    }

    sortAZ(jobs: []) {
        jobs.sort((jobA: any, jobB: any) => {
            const jobNameA = jobA.name.toLowerCase();
            const jobNameB = jobB.name.toLowerCase();

            if (jobNameA < jobNameB) {
                return -1;
            }
            if (jobNameA > jobNameB) {
                return 1;
            }
            return 0;
        });
    }

    sortZA(jobs: []) {
        jobs.sort((jobA: any, jobB: any) => {
            const jobNameA = jobA.name.toLowerCase();
            const jobNameB = jobB.name.toLowerCase();

            if (jobNameA > jobNameB) {
                return -1;
            }
            if (jobNameA < jobNameB) {
                return 1;
            }
            return 0;
        });
    }

    sortAscending(jobs: []) {
        jobs.sort((jobA: any, jobB: any) => {
            const jobinterimCountA = jobA.interimCount;
            const jobinterimCountB = jobB.interimCount;

            if (jobinterimCountA < jobinterimCountB) {
                return -1;
            }
            if (jobinterimCountA > jobinterimCountB) {
                return 1;
            }
            return 0;
        });
    }

    sortDescending(jobs: []) {
        jobs.sort((jobA: any, jobB: any) => {
            const jobinterimCountA = jobA.interimCount;
            const jobinterimCountB = jobB.interimCount;

            if (jobinterimCountA > jobinterimCountB) {
                return -1;
            }
            if (jobinterimCountA < jobinterimCountB) {
                return 1;
            }
            return 0;
        });
    }

    created() {
        if (this.isCompany) {
            this.$set(this.visualisations, 'mode', "gantt-workers");
        }
        if (this.isGanttAvailabilities) {
            this.$set(this.visualisations, 'mode', "planningWorkers");
        }
        window.addEventListener("resize", () => {
            this.onResize();
        });
    }

    destroyed() {
        // clearInterval(this.synchroTimeout);
        window.removeEventListener("resize", () => {
            this.onResize();
        });
    }

    async beforeMount() {
        if (this.$route.query.mode) {
            // @ts-ignore;
            this.pageTitle = <string>this.$t('page.interims.titles')[this.$route.query.mode];
        }

        if (this.$route.query.view === 'jobs' && !this.jobs.length) {
            this.jobs = await this.loadInterimJobs({view: this.storeSelectedView?.nameSlug});
            const lastJob = this.jobs.find((job: any) => job._id === this.$route.query.jobId);
            this.selectedJob = lastJob || this.jobs[0];
        }

        if (this.isGanttAvailabilities) {
            const from = parseInt(<string>this.$route.query.from) || moment().startOf('week').valueOf()
            const to = parseInt(<string>this.$route.query.to) || moment().endOf('week').valueOf()
            this.visualisations.dateRange = { from, to };
        }

        this.timedSearch({
            ...this.$route.query,
            currentPage: parseInt(<string>this.$route.query.page) || 1,
            currentPerPage: parseInt(<string>this.$route.query.perPage) || 50,
            offset: parseInt(<string>this.$route.query.offset) || 0
        });
    }

    toggleAddInterimVerificationModal() {
        this.showWorkerAddVerificationModal = !this.showWorkerAddVerificationModal ;
        if(this.showWorkerAddVerificationModal) showModal.call(this, 'WorkerAddVerificationModal');
        else this.$modal.hide('WorkerAddVerificationModal');
    }
}
