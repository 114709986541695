
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import ModalMixin from "@/components/modal/ModalMixin.vue";
    import {Getter} from "vuex-class";
    import {LabelValue} from "@/types";

    @Component({
        name: "ActiveInterim"
    })
    export default class ActiveInterim extends mixins(ModalMixin) {
        name = "ActiveInterim";

        reason = "";
    }
