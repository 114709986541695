
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import {IAgencyDB, IInterimDB} from "@/types";
    import {Getter, State} from "vuex-class";

    @Component({
        name: "InterimActions"
    })
    export default class InterimActions extends Vue {
        @Prop(String) mode!: string;
        @Prop(Boolean) invitation!: boolean;
        @Prop(Boolean) disabled!: boolean;
        @Prop() groupStatus!: string[];
        @Prop() interim!: IInterimDB;
        @Getter('isAgency') isAgency!: boolean;
        @Getter('isInteractionLimited') isInteractionLimited!: boolean
        @State('currentAgency') currentAgency!: IAgencyDB;

        get isInterimImported() {
            return this.interim?.status === "imported";
        }

        get isInterimInvited() {
            return this.interim?.status === "invited";
        }

        get isInactive() {
            return this.isAgency && this.interim?.inactiveByAgencies && this.interim?.inactiveByAgencies[this.currentAgency._id] &&  this.interim?.agenciesList && (this.interim.agenciesList?.includes(this.currentAgency._id));
        }

        get isNoLongerRegistered() {
            return this.isAgency && this.interim?.inactiveByAgencies && this.interim?.inactiveByAgencies[this.currentAgency._id] && !(this.interim.agenciesList?.includes(this.currentAgency._id));
        }

        get isGroupMode() {
            return this.mode === "group";
        }

        get isGroupInactive() {
            if (this.groupStatus) {
            return this.isGroupMode && (this.groupStatus.find(s => s == 'inactive') == 'inactive')
            } else {
                return false
            }
        }

        get hasViewDetails() {
            return !this.isGroupMode;
        }
    }
