
import {Component, Vue, Prop} from 'vue-property-decorator';
import {capitalize} from "@/helpers/commons";
import moment from "moment-timezone";

@Component({
    name: "InterimsPlanning"
})
export default class InterimsPlanning extends Vue {
    @Prop() interims!: any;
    @Prop() pagination!: any;
    @Prop() ranges!: any;

    get headers() {
        const fromDate = moment(this.ranges.from);
        let headers: any = [];
        let i = 0, format;

        format = "dddd DD";
        while (i < 7) {
            const date = fromDate.clone().add(i, 'day');

            headers.push({
                label: capitalize(date.format(format)),
                value: date.valueOf()
            });

            ++i;
        }

        headers.splice(0, 0, {
            label: <string>this.$t('page.clients.topbar.interims'),
            value: '_interims',
            filterable: false
        });

        return headers;
    }
}
