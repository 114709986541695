
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import ModalMixin from "@/components/modal/ModalMixin.vue";
    import {Action} from "vuex-class";
    import {IDeleteReasonDB, LabelValue, StoreAction} from "@/types";

    @Component({
        name: "DeleteInterim"
    })
    export default class DeleteInterim extends mixins(ModalMixin) {
        name = "DeleteInterim";
        @Prop() custom_title: any;
        @Prop() description: any;

        @Action('actions/loadDeletedReasons') loadDeletedReasons!: StoreAction;

        reason = "";
        reasonComplement ="";
        deleteReasonOptions: LabelValue[] = [];

        async getDeleteReasons() {
            this.deleteReasonOptions = [];
            (await this.loadDeletedReasons()).forEach((element: IDeleteReasonDB) => {
                this.deleteReasonOptions.push({label: element.reason , value: element.slug });
            });
        }

        isOther(): boolean {
            return this.reason === "autre";
        }

        beforeMount() {
        this.getDeleteReasons();
        this.reason = this.deleteReasonOptions.length>0?this.deleteReasonOptions[0].value:"";
    }

    }
